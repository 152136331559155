import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { translateSkillKindEnum } from '../../../helper/tasksEnum';
import { createNotify } from '../../../redux/actions/notify';
import Grade from '../../global/Grade/Grade';
import showNotification from '../../global/Notification/showNotification';
import TopicSymbol from '../../global/TopicSymbol/TopicSymbol';
import Button from '../../global/form/Button/Button';
import './InvestmentsTableRow.scss';

const InvestmentsTableRow = ({ execution, setSeletedContribution, setOpenDetails, setSelectedContributionTasks }) => {
  const dispatch = useDispatch()
  const [revaluationButtonVisible, setRevaluationButtonVisible] = useState(true);
  
  const handleNotify = (dataNotify) => {
    const payload = {
      title: `Se avalie novamente no investimento: ${dataNotify.name}`,
      type: "GENERIC",
      units: dataNotify.units,
      roles: dataNotify.roles
    }

    dispatch(createNotify(payload)).then(() => {
      setRevaluationButtonVisible(false)
      showNotification('Notificação enviada com sucesso!', 5000);
    }).catch(() => showNotification('Houve um erro inesperado, tente mais tarde!', 5000))
  }
  return (
    <tr className={`InvestmentsTableRow main `}>
      <td>
        <TopicSymbol symbol={execution?.topic?.symbol} />
      </td>
      <td>{execution?.topic?.name}</td>
      <td>
        <Grade value={execution?.tracking ? execution?.tracking?.average : 0} variation={execution?.tracking ? execution?.tracking?.variation : 0} />
        {revaluationButtonVisible && <Button
          linkStyle
          secondaryColor
          onClick={() => handleNotify(execution)}
        >
          Pedir reavaliação
        </Button>}
      </td>
      <td>{execution?.topic?.skillKind ? translateSkillKindEnum(execution.topic?.skillKind) : "Indefinido"}</td>
      <td>
        <p>{execution?.popularity?.activeTasksCount ? execution?.popularity?.activeTasksCount : 0} registrados</p>
        <Button
          linkStyle
          secondaryColor
          onClick={() => {
            setOpenDetails(true)
            setSeletedContribution(execution)
            setSelectedContributionTasks(execution.popularity)
          }}
        >
          Ver aportes
        </Button>
      </td>
      <td>
        <p>{execution?.popularity?.affiliations ? execution?.popularity?.affiliations : 0} investidores</p>
        <Button
          linkStyle
          secondaryColor
          onClick={() => {
            setOpenDetails(true)
            setSeletedContribution(execution)
            setSelectedContributionTasks(execution.popularity)
          }}
        >
          Ver
        </Button>
      </td>
      <td>
        <Button
          onClick={() => {
            setOpenDetails(true)
            setSeletedContribution(execution)
            setSelectedContributionTasks(execution.popularity)
          }}
          className="button-details"
        >
          VER DETALHES
        </Button>
      </td>
    </tr>
  );
}

export default InvestmentsTableRow
