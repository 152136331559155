import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { splitDate } from "../../../helper/dateFormat";
import { selectLastDays } from "../../../helper/selectLastDays";
import { getCompanyStructure } from "../../../redux/actions/structure";
import { getTopicFilteredEvolution } from "../../../redux/actions/topics";
import Card from "../../global/Card/Card";
import Select from "../../global/form/Select/Select";
import CompanyAbilitiesTable from "../CompanyAbilitiesTable/CompanyAbilitiesTable";
import LineChart from "../LineChart/LineChart";
import "./AbilitiesAnalysis.scss";

const AbilitiesAnalysis = () => {
  const [selectedAbility, setSelectedAbility] = useState(null);
  const demo = useSelector(state => state.demo);
  const topics = useSelector(state => state.topics);
  const dispatch = useDispatch();

  const getChartData = () => {
    const monthNames = ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"];
    let chartData = topics.evolutionFiltered?.data || [];

    if (chartData.length === 0) {
      return [];
    }

    const groupedData = chartData.reduce((acc, item) => {
      const yearMonthKey = `${item.year}-${item.month}`;
      if (!acc[yearMonthKey]) {
        acc[yearMonthKey] = item;
      } else {
        acc[yearMonthKey] = {
          ...acc[yearMonthKey],
          average: Math.max(acc[yearMonthKey].average, item.average),
          max: Math.max(acc[yearMonthKey].max, item.max),
          min: Math.min(acc[yearMonthKey].min, item.min),
        };
      }
      return acc;
    }, {});

    return Object.values(groupedData).map(item => ({
      ...item,
      monthName: monthNames[item.month - 1],
    }));
  };

  useEffect(() => {
    if (selectedAbility === -1) {
      if (!demo.isDemoMode && topics.topicsList.data && topics.topicsList.data.length > 0) {
        setSelectedAbility(topics.topicsList.data[0].topic.id);
      } else {
        setSelectedAbility(demo.data.topics?.topicsList[0].topic.id)
      }
    }
  }, [topics?.topicsList.data, demo.isDemoMode, demo.data.topics?.topicsList])

  const [selectedUnit, setSelectedUnit] = useState(null);
  const [roles, setRoles] = useState([]);
  const structure = useSelector((state) => state?.structure?.data);

  const handleSelectChange = (event) => {
    const selectedValue = event;
    setSelectedUnit(selectedValue);

    const selectedUnitData = structure.find(unit => unit.name === selectedValue);
    if (selectedUnitData) {
      setRoles(selectedUnitData.roles);
    } else {
      setRoles([]);
    }
  };

  const [selectedRole, setSelectedRole] = useState('');
  const handleRoleChange = (event) => {
    setSelectedRole(event);
  };

  const [period, setPeriod] = useState("últimos 30 dias");
  const getFromDate = useCallback(() => {
    const selectPeriod = selectLastDays(period)
    let today = new Date();
    today = today.setDate(today.getDate() - selectPeriod);
    let date = new Date(today);
    return splitDate(date.toISOString());
  }, [period])

  useEffect(() => {
    dispatch(getCompanyStructure());
  }, [])

  const getFromDateLast12Months = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 12);
    return date.toISOString().split("T")[0];
  };

  useEffect(() => {
    if (selectedAbility) {
      const payloadFetch = {
        from: getFromDateLast12Months(),
      }

      if (selectedRole) { payloadFetch.role = selectedRole }
      if (selectedUnit) { payloadFetch.unit = selectedUnit }

      dispatch(getTopicFilteredEvolution(selectedAbility, payloadFetch))
    }

  }, [selectedAbility, period, selectedRole, selectedUnit])

  const filteredTasks = topics.topicsList.data
    .filter((task) => {
      const { units } = task.topic;
      if (selectedUnit === ' ') {
        return true;
      }
      return units && (units.includes(selectedUnit) || units.includes('*'));
    })
    .sort((a, b) => a?.topic?.name?.localeCompare(b?.topic?.name));

  return (
    <section className="AbilitiesAnalysis">
      <h2>Investimentos dos integrantes</h2>
      <p className="subtitle">Aqui, os investimentos são as competências da equipe. O patrimônio de cada um é seu conhecimento. Cada
        colaborador seleciona os investimentos que possui ou deseja desenvolver e realiza uma auto-avaliação, atribuindo
        um valor de proficiência naquela competência.</p>
      <Card container>
        <div className="filters">
          <p>{demo.isDemoMode ? demo.data.topics.topicsList.length : topics.topicsList.data.length} competência{(demo.isDemoMode ? demo.data.topics.topicsList.length : topics.topicsList.data?.length > 1) || topics.topicsList.data?.length === 0 ? "s" : ""}</p>
          <div className="select-container">
            <Select
              defaultOption="Todas as áreas"
              updateState={handleSelectChange}
              options={Array.isArray(structure) && structure?.map(unit => unit.name)}
              value={selectedUnit || 'Selecione uma unidade'}
            />
            <Select
              updateState={handleRoleChange}
              options={roles.length > 0 ? roles : ["Selecione uma área para liberar os níveis"]}
              value={selectedRole || ''}
              defaultOption="Todos os níveis"
            />
          </div>
        </div>
        <div className="chart-container">
          {demo.data.topics?.topicsList.length > 0 || topics.topicsList?.data.length > 0 ? (
            <CompanyAbilitiesTable
              companyAbilities={demo.isDemoMode ? demo.data.topics?.topicsList : filteredTasks}
              selectedAbility={selectedAbility}
              setSelectedAbility={setSelectedAbility}
            />
          ) : (<p>Nenhuma competencia a ser exibida!</p>)}
          <div className="line-chart">
            <p className="title-chart">Valores consolidados <span>Mensais</span></p>
            {topics.evolutionFiltered?.isFetching ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "20px",
                  color: "#fff",
                  zIndex: 1
                }}
              >
                Carregando...
              </div>
            ) : (
              <LineChart data={getChartData()} />
            )}
          </div>
        </div>
      </Card>
    </section>
  );
};

export default AbilitiesAnalysis;
