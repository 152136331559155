import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import { translateGestorLevel, translateGestorLevels } from "../../../helper/dataHelper";
import { inviteManager } from "../../../redux/actions/invites";
import { getManagers, updateManager } from "../../../redux/actions/managers";
import { getCompanyStructure } from "../../../redux/actions/structure";
import Loading from "../../global/Loading/Loading";
import PopUpCheck from "../../global/PopUpCheck/PopUpCheck";
import SaveModal from "../../global/SaveModal/SaveModal";
import "./InviteModal.scss";

const InviteModal = ({ onClose, manager }) => {
  const dispatch = useDispatch();
  const [selectedRole, setSelectedRole] = useState("Administrador");
  const structure = useSelector((state) => state?.structure);
  const [units, setUnits] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [popUpActive, setPopUpActive] = useState(false);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAddArea = (newArea) => {
    setUnits([...units, newArea]);
  };

  useEffect(() => {
    dispatch(getCompanyStructure());
  }, [dispatch]);

  useEffect(() => {
    if (manager) {
      setEmail(manager?.manager?.email);
      setSelectedArea(manager?.units[0] === "*" ? "Acesso completo" : manager?.units);
      setSelectedRole(translateGestorLevel(manager?.level))
    }
  }, [manager]);

  useEffect(() => {
    if (Array.isArray(structure?.data)) {
      setUnits(structure?.data?.map((unit) => (unit.name)));
      setUnits((prevState) => [
        ...prevState,
        "Acesso completo",
      ]);
    }
  }, [structure?.data]);

  const handleCheckboxChange = (area) => {
    setSelectedArea((prevSelected) =>
      prevSelected.includes(area)
        ? prevSelected.filter((a) => a !== area)
        : [...prevSelected, area]
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    if (manager) {
      dispatch(updateManager(manager?.id, { units: selectedArea.includes("Acesso completo") ? ["*"] : selectedArea, level: translateGestorLevels(selectedRole) })).then(() => {
        setPopUpActive(true);
        setIsLoading(false);
        dispatch(getManagers());
      })
    } else {
      if (!email) newErrors.email = "E-mail é obrigatório.";
      if (!selectedArea) newErrors.selectedArea = "Selecione uma área.";

      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
      } else {
        setIsLoading(true);
        const payload = {
          email,
          units: selectedArea.includes("Acesso completo") ? ["*"] : selectedArea,
          level: translateGestorLevels(selectedRole)
        };
        dispatch(inviteManager(payload)).then(() => {
          setPopUpActive(true);
          setIsLoading(false);
        });

        setErrors({});
      }
    }
  };

  return (
    <div className="InviteModal">
      <div className="invite-modal">
        <Logo className="logo" />
        <button className="close-button" onClick={onClose}>
          FECHAR <span class="material-symbols--close" />
        </button>
        <div className="invite-modal-header">
          {!manager && <h2>Acessos <span className="solar--alt-arrow-right-line-duotone" />Convidar gestores</h2>}
          {manager && <h2>Acessos <span className="solar--alt-arrow-right-line-duotone" />Gestores <span className="solar--alt-arrow-right-line-duotone" /> Editar</h2>}
          <p>Aqui, você pode gerenciar os usuários e permissões da plataforma.</p>
        </div>
        <div className="container-box-invite">
          <form onSubmit={handleSubmit}>
            <div className="invite-section">
              <h3>Para quem você quer enviar esse convite?</h3>
              <div className="inputs-box">
                <div>
                  <input
                    type="email"
                    placeholder="E-mail"
                    disabled={manager}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      if (e.target.value) {
                        setErrors((prev) => ({ ...prev, email: null }));
                      }
                    }}
                  />
                  {errors.email && <span className="error">{errors.email}</span>}
                </div>
                <div className="radio-select-container">
                  <h3 className="title">Quais áreas estarão acessíveis para essa pessoa gestora?</h3>
                  <p className="subtitle">
                    Selecione uma ou mais áreas disponíveis ou adicione uma nova.
                  </p>
                  <div className="checkbox-options">
                    {units.map((area, index) => (
                      <label key={index} className="checkbox-label">
                        <input
                          type="checkbox"
                          value={area}
                          checked={selectedArea.includes(area)}
                          onChange={() => handleCheckboxChange(area)}
                          className="checkbox-input"
                        />
                        <span className="checkbox-box" />
                        {area}
                      </label>
                    ))}
                  </div>
                  <button type="button" onClick={() => setIsModalOpen(true)} className="add-new-button">ADICIONAR NOVA <span class="ri--add-line" /></button>
                  {errors.selectedArea && <span className="error">{errors.selectedArea}</span>}
                </div>
              </div>
            </div>
            <div className="role-section">
              <h3>Qual será o cargo de acesso?</h3>
              <div>
                <div className="box-radio">
                  <label>
                    <input
                      type="radio"
                      name="role"
                      value="Administrador"
                      checked={selectedRole === "Administrador"}
                      onChange={(e) => setSelectedRole(e.target.value)}
                    />
                    <span className="radio-label">Administrador(a)</span>
                  </label>
                  <span className="description">
                    Qualquer pessoa administradora pode ver, editar e criar novas competências.
                  </span>
                </div>

                <div className="box-radio">
                  <label>
                    <input
                      type="radio"
                      name="role"
                      value="Coordenador"
                      checked={selectedRole === "Coordenador"}
                      onChange={(e) => setSelectedRole(e.target.value)}
                    />
                    <span className="radio-label">Coordenador(a)</span>
                  </label>
                  <span className="description">
                    Qualquer pessoa coordenadora pode ver e criar novas competências.
                  </span>
                </div>
                <div className="box-radio">
                  <label>
                    <input
                      type="radio"
                      name="role"
                      value="Gerente"
                      checked={selectedRole === "Gerente"}
                      onChange={(e) => setSelectedRole(e.target.value)}
                    />
                    <span className="radio-label">Gerente</span>
                  </label>
                  <span className="description">
                    Qualquer pessoa gerente pode ver.
                  </span>
                </div>
              </div>

              <button type="submit" className="submit-button">
                {manager ? 'SALVAR' : 'ENVIAR CONVITE'} <span className="fa6-solid--arrow-right" />
              </button>
            </div>
          </form>
        </div>
      </div>
      <Loading visible={structure.isFetching || isLoading} message="Carregando dados" />
      {popUpActive && <PopUpCheck
        buttonAction={() => {
          setPopUpActive(false)
          onClose();
        }}
        active={popUpActive}
        children={<p>{manager ? 'SALVO COM SUCESSO!' : 'CONVITE ENVIADO COM SUCESSO!'}</p>}
      />}
      <SaveModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleAddArea}
      />
    </div>
  );
};

export default InviteModal;
