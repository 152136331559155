import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listInvestmentsSummary } from "../../../redux/actions/investments";
import { getCommunityMetrics } from "../../../redux/actions/metrics";
import { getTrending } from "../../../redux/actions/trending";
import Card from "../../global/Card/Card";
import CommunityMetricsDistribution from "../../global/CommunityMetricsDistribution/CommunityMetricsDistribution";
import Button from "../../global/form/Button/Button";
import CreateInvestment from "../CreateInvestment/CreateInvestment";
import CreateNotifyModal from "../CreateNotifyModal/CreateNotifyModal";
import InvestmentDetails from "../InvestmentDetails/InvestmentDetails";
import InvestmentsTable from "../InvestmentsTable/InvestmentsTable";
import "./Investments.scss";

const Investments = () => {
  const [selectedContribution, setSeletedContribution] = useState(null);
  const [selectedContributionTasks, setSelectedContributionTasks] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showNotifyModal, setShowNotifyModal] = useState(false);
  const [dataNotify, setDataNotify] = useState("");
  const investments = useSelector((state) => state.investments);
  const [openDetails, setOpenDetails] = useState(false);
  const dispatch = useDispatch();
  const companyMetricsEngagement = useSelector((state) => state?.metrics);
  const communityMetrics = companyMetricsEngagement?.communityMetrics?.data;
  const [currentPage, setCurrentPage] = useState(1);
  const buildFilters = () => {
    let filters = {};
    return filters;
  };

  const onPageChange = (newPage) => {
    if (newPage !== currentPage) {
      let filters = buildFilters();
      filters.page = newPage;
      setCurrentPage(newPage);
      dispatch(listInvestmentsSummary(filters));
    }
  };
  const pageNumbers = Array.from(
    { length: investments?.data?.totalPages },
    (_, i) => i + 1
  );

  useEffect(() => {
    dispatch(listInvestmentsSummary());
    dispatch(getTrending());
    dispatch(getCommunityMetrics());
  }, []);

  useEffect(() => {
    if (showModal || showNotifyModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    if (!openDetails) {
      setSeletedContribution(null);
    }
  }, [showModal, showNotifyModal, openDetails]);

  const handleShowInvestmentModal = (contribution) => {
    setDataNotify(contribution);
    setShowModal(false);
    setShowNotifyModal(true);
  };

  return (
    <div className="Investments">
      {/* <TrendingTopics /> */}
      <div className="info-investments">
        <h2>Investimentos</h2>
        <p className="investments">
          <strong>Os investimentos são as competências da equipe.</strong>
          &nbsp;O patrimônio de cada um é seu <br /> conhecimento. Cada colaborador
          seleciona os investimentos que possui ou deseja desenvolver <br /> e
          realiza uma auto-avaliação, atribuindo um valor de proficiência
          naquela competência.
        </p>
      </div>
      <div className="container-cards-investments">
        <Card title="Alocação de Carteira" className="card-alocation">
          <CommunityMetricsDistribution
            community={communityMetrics}
            skill
          />
        </Card>
        <div className="values-investments">
          <Card className="card-value" container title="Maior valor">
            <Button linkStyle
              onClick={() => {
                setSeletedContribution(communityMetrics?.topicWithHighestValue)
                setOpenDetails(true)
              }}
            >Ver Detalhes</Button>
            <article>
              <p>
                {communityMetrics?.topicWithHighestValue?.name || "Sem valor"}
              </p>
              <p className="grade">
                {communityMetrics?.topicWithHighestValue?.tracking.average.toFixed(
                  2
                ) || ""}
              </p>
            </article>
          </Card>
          <Card className="card-value" container title="Menor valor">
            <Button linkStyle
              onClick={() => {
                setSeletedContribution(communityMetrics?.topicWithLowestValue)
                setOpenDetails(true)
              }}
            >Ver Detalhes</Button>
            <article>
              <p>
                {communityMetrics?.topicWithLowestValue?.name || "Sem valor"}
              </p>
              <p className="grade">
                {communityMetrics?.topicWithLowestValue?.tracking.average.toFixed(
                  2
                ) || ""}
              </p>
            </article>
          </Card>
        </div>
      </div>
      <div className="table-investments">
        <div className="infos-table">
          <h4>Investimentos da Comunidade</h4>
          <p className="number-investments">
            {investments?.data?.totalItems} competências
          </p>
        </div>
        <div className="button-create-investment">
          <Button onClick={() => setShowModal(true)}>
            Adicionar nova competência
          </Button>
        </div>
        <div className="container-table">
          <InvestmentsTable
            setOpenDetails={setOpenDetails}
            setSeletedContribution={setSeletedContribution}
            executions={investments.data.items}
            fetching={investments.isFetching}
            setSelectedContributionTasks={setSelectedContributionTasks}
          />
        </div>
        <div className="container-buttons">
          {pageNumbers.map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => onPageChange(pageNumber)}
              className={`button-pagination ${pageNumber === currentPage ? "active" : ""
                }`}
            >
              {pageNumber}
            </button>
          ))}
        </div>
      </div>

      {showModal && (
        <CreateInvestment
          showModal
          handleCloseDetails={() => setOpenDetails(false)}
          selectedContribution={selectedContribution}
          handleShowInvestmentModal={handleShowInvestmentModal}
          handleNotifyModal={() => setShowNotifyModal(true)}
          handleShowModal={() => setShowModal(false)}
        />
      )}

      {showNotifyModal && (
        <CreateNotifyModal
          showNotifyModal
          dataNotify={dataNotify}
          handleShowModal={() => setShowNotifyModal(false)}
        />
      )}

      {openDetails && (
        <InvestmentDetails
          edit
          topic
          setShowModal={setShowModal}
          investment={selectedContribution}
          investmentTasks={selectedContributionTasks}
          handleClose={() => {
            setOpenDetails(false)
            setSelectedContributionTasks(null)
          }}
        />
      )}
    </div>
  );
};

export default Investments;
