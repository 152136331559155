import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from "react-router-dom";
import Button from "../../global/form/Button/Button";
import EmployeesTable from "../EmployeesTable/EmployeesTable";
import ManagersTable from "../ManagersTable/ManagersTable";
import "./TableContainer.scss";

const TableContainer = ({ data, setPage, hideTable }) => {
  const pages = data.totalPages;
  const { type } = useParams();

  return (
    <div className={`TableContainer ${hideTable ? "inactive" : ""}`}>
      {type === "gestores" ? <ManagersTable data={data} /> : <EmployeesTable data={data} />}
      <div className="table-footer">
        {data.totalPages > 1 && (
          <div className="pagination">
            <Button
              onClick={() => setPage(Math.max(1, data.page - 1))}
              disabled={data.page === 1}
            >
              Anterior
            </Button>

            {data.page > 2 && (
              <Button onClick={() => setPage(1)}>1</Button>
            )}
            {data.page > 3 && <span>...</span>}
            {data.page > 1 && (
              <Button onClick={() => setPage(data.page - 1)}>{data.page - 1}</Button>
            )}

            <Button className="active" onClick={() => setPage(data.page)}>
              {data.page}
            </Button>

            {data.page < data.totalPages && (
              <Button onClick={() => setPage(data.page + 1)}>{data.page + 1}</Button>
            )}
            {data.page < data.totalPages - 2 && <span>...</span>}
            {data.page < data.totalPages - 1 && (
              <Button onClick={() => setPage(data.totalPages)}>{data.totalPages}</Button>
            )}

            <Button
              onClick={() => setPage(Math.min(data.totalPages, data.page + 1))}
              disabled={data.page === data.totalPages}
            >
              Próximo
            </Button>
          </div>
        )}
      </div>


    </div>
  );
};

TableContainer.propTypes = {
  data: PropTypes.array.isRequired
};

export default TableContainer;
